.profile_wrapper {
    width: 1700px;
    margin: 0 auto;
}

.detail_order {
    width: 1700px;
    margin: 0 auto;
}

.detail_order-sum {
    font-size: 18px;
    margin-left: 40px;
}

.profile_card {
    width: 900px;
    padding: 60px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
}

.profile_card-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}


.profile_card-info {
    display: flex;
    margin-top: 25px;

}

.input-row + .input-row {
    margin-left: 10px;
}

.card_profile-input {
    display: block;
    width: 400px;
    height: 35px;
    border-radius: 5px;
    border: none;
    padding: 10px;
    background-color: rgba(236, 236, 236, 1);
}

.card_profile-input + .card_profile-input {
    margin-top: 8px;
}

.history_order {
    width: 900px;
    margin: 50px auto;
    padding: 60px;
    border-radius: 20px;

}

.history_order-wrapper {
    text-decoration: none;
}


.history_order-item {
    justify-content: space-between;
    background-color: rgba(236, 236, 236, 1);
    padding: 12px 20px 12px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    transition: .25s;
}

.history_order-item:hover {
    background-color: rgb(209, 210, 213);
}

.history_order-title {
    font-size: 32px;
    margin-bottom: 30px;
}

.detail_order-info {
    width: 400px;
    padding: 10px;
    border-radius: 20px;
    margin: 30px 10px 10px 0px;
}

.detail_order-status {
    color: #de5e24;
    font-weight: bold;
}

.backBtn {
    width: 100px;
    height: 50px;
    cursor: pointer;
    margin: 30px 10px 10px 0px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    transition: .25s    ;
}

.backBtn:hover {
    background-color: rgba(169, 169, 169, 0.87);
}
