.users_search {
    display: block;
    width: 1100px;
    height: 30px;
    margin: 40px auto;
    border-radius: 10px;
    border: none;
    background-color: white;
    padding: 10px;
}

.user_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    width: 1700px;
    margin: 0 auto;
}


.user_block {
    width: 300px;
    padding: 30px;
    border-radius: 15px;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: unset;

}

.user_block-text {
    margin: 0;
}

.user_block-btns{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.user_block-btn {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 10px;
    background-color: transparent;
    transition: .25s;
    margin-bottom: 10px;

}

.user_block-btn:hover {
    background-color: rgba(169, 169, 169, 0.87);
}



.create_user {
    width: 100px;
    height: 40px;
    color: black;
    border-radius: 10px;
    border: 1px solid black;
    margin-left: 100px;
}

.create_user:hover {
    background-color: rgba(169, 169, 169, 0.87);
}



