
.orders {
    width: 1700px;
    display: flex;
    flex-direction: column;
}

.order-item {
    width: 1500px;
    justify-content: space-between;
    background-color: rgba(236, 236, 236, 1);
    padding: 12px 20px 12px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    transition: .25s;
}

.orders_wrapper {
    width: 1700px;
    margin: 0 auto;
}

.orders_select {
    width: 300px;
    height: 30px;
    border: 1px solid #929292;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 5px;
}

.order_btn {
    width: 150px;
    height: 70px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 14px;
    transition: .25s;
    border: none;
    margin-left: 20px;
}



.order_btn:hover {
    background-color: rgba(169, 169, 169, 0.87);
}
