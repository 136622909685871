#menu__toggle {
    opacity: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
    visibility: visible;
    left: 0;
}

.menu__btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 100px;

    width: 26px;
    height: 26px;

    cursor: pointer;
    z-index: 2;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    display: block;
    position: absolute;

    width: 100%;
    height: 2px;

    background-color: #616161;

    transition-duration: .25s;
}
.menu__btn > span::before {
    content: '';
    top: -8px;
}
.menu__btn > span::after {
    content: '';
    top: 8px;
}

.menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;

    width: 330px;
    height: 100%;

    margin: 0;
    padding: 80px 0;

    list-style: none;

    background-color:white;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

    transition-duration: .25s;

    z-index: 1;
}

.menu__item {
    display: block;
    padding: 10px 24px;

    color: #333;

    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;

    transition-duration: .25s;
}
.menu__item:hover {
    background-color: #CFD8DC;
}

.menu__item-btn {
    text-align: left;
    background-color: white;
    height: 100%;
    transition-duration: .25s;
}


.menu__item-btn:hover {
    background-color: #CFD8DC;
}


.menu__box-container {
    margin-top: 80px;
    margin-left: 30px;
}

